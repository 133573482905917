import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { logout } from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";

const AdminLinksComponent = () => {
    const dispatch = useDispatch()
  return (
    <Navbar bg="light" variant="secondary">
      <Nav className="flex-column">
      <LinkContainer to="/admin/categories">
          <Nav.Link>Categorie</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/products">
          <Nav.Link>Argomenti</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/users">
          <Nav.Link>Utenti</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/chats">
          <Nav.Link>Chat</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/analytics">
          <Nav.Link>Analytics</Nav.Link>
        </LinkContainer>
        <Nav.Link onClick={() => dispatch(logout())}>Logout</Nav.Link>
        {/* 
        <LinkContainer to="/admin/orders">
          <Nav.Link>Ordini</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/coupons">
          <Nav.Link>Coupons</Nav.Link>
        </LinkContainer>
        <LinkContainer to="/admin/giftcards">
          <Nav.Link>Gift Card</Nav.Link>
        </LinkContainer>
        */}
      </Nav>
    </Navbar>
  );
};

export default AdminLinksComponent;
