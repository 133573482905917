import EditCategoryPageComponent from "./components/EditCategoryPageComponent";

import axios from "axios";

const fetchCategory = async (categoryId) => {
    const { data } = await axios.get(`/api/categories/${categoryId}`);
    return data;
}

const updateCategoryApiRequest = async (categoryId, name, description, image) => {
    const { data } = await axios.put(`/api/categories/${categoryId}`, { name, description, image });
    return data;
}

const AdminEditCategoryPage = () => {
  
  return <EditCategoryPageComponent updateCategoryApiRequest={updateCategoryApiRequest} fetchCategory={fetchCategory} />;
};


export default AdminEditCategoryPage;
