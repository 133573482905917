import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import 'bootstrap/dist/css/bootstrap.css'; 
import Carousel from 'react-bootstrap/Carousel'; 

const config = require("../../constants/constants.js")
const site_image_login = "../images-"+config.CUSTOMER_CODE+config.URL_IMAGES_CUST_LOGIN;


const LoginPageComponent = ({ loginUserApiRequest,reduxDispatch, setReduxUserState  }) => {
  const [validated, setValidated] = useState(false);
  const [loginUserResponseState, setLoginUserResponseState] = useState({
    success: "",
    error: "",
    loading: false,
  });

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;

    const email = form.email.value;
    const password = form.password.value;
    const doNotLogout = false;

    if (event.currentTarget.checkValidity() === true && email && password) {
        setLoginUserResponseState({ loading: true });
      loginUserApiRequest(email, password, doNotLogout)
        .then((res) => {
            setLoginUserResponseState({ success: res.success, loading: false, error: "" });

            if (res.userLoggedIn) {
                reduxDispatch(setReduxUserState(res.userLoggedIn));
            }

            if (res.success === "user logged in" && !res.userLoggedIn.isAdmin) window.location.assign('/') 
            else window.location.assign('/admin/categories')

        })
        .catch((er) =>
          setLoginUserResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data })
        );
    }

    setValidated(true);
  };
  
  return (
    <Container className="px-0">
    <Row
      g-0
      className="pt-2 pt-md-5 w-100 px-4 px-xl-0 position-relative"
    >
      <Col
        xs={{ order: 2 }}
        md={{ size: 4, order: 1 }}
        tag="aside"
        className="pb-5 mb-5 pb-md-0 mb-md-0 mx-auto mx-md-0"
      >

        <Carousel> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_LOGIN_SLIDE_1_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_LOGIN_SLIDE_1_TITOLO}</h3> 
              <p>{config.PAGE_LOGIN_SLIDE_1_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_LOGIN_SLIDE_2_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_LOGIN_SLIDE_2_TITOLO}</h3> 
              <p>{config.PAGE_LOGIN_SLIDE_2_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
          <Carousel.Item interval={2500}> 
            <img 
              className="d-block w-100"
              src={config.PAGE_LOGIN_SLIDE_3_URL}
              alt="immmagine del prodotto"
            /> 
            <Carousel.Caption> 
              <h3>{config.PAGE_LOGIN_SLIDE_3_TITOLO}</h3> 
              <p>{config.PAGE_LOGIN_SLIDE_3_SOTTOTITOLO}</p> 
            </Carousel.Caption> 
          </Carousel.Item> 
        </Carousel> 
      </Col>

      <Col
        xs={{ order: 1 }}
        md={{ size: 7, offset: 1 }}
        tag="section"
        className="py-5 mb-5 py-md-0 mb-md-0"
      >

<h1>Login</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                name="email"
                required
                type="email"
                placeholder="inserisci email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                required
                type="password"
                placeholder="Password"
              />
            </Form.Group>

            <Row className="pb-2">
              <Col>
                Non hai un account?
                <Link to={"/register"}> Crea account</Link>
              </Col>
            </Row>

            <Button variant="primary" type="submit">
              {loginUserResponseState &&
              loginUserResponseState.loading === true ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                ""
              )}
              Login
            </Button>
            <Alert
              show={
                loginUserResponseState &&
                loginUserResponseState.error === "wrong credentials"
              }
              variant="danger"
            >
              Credenziali sbagliate
            </Alert>
          </Form>

      </Col>
    </Row>
    
    <Row className="mt-5 justify-content-md-center">
      <Col md={6}>
      </Col>
    </Row>

  </Container>

  );
};

export default LoginPageComponent;
