import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditCategoryPageComponent = ({ updateCategoryApiRequest, fetchCategory }) => {
  const [validated, setValidated] = useState(false);
    const [category, setCategory] = useState([]);
    const [updateCategoryResponseState, setUpdateCategoryResponseState] = useState({ message: "", error: "" });

    const { id } = useParams();
    const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const form = event.currentTarget.elements;
    const name = form.name.value;
    const description = form.description.value;
    const image = form.image.value;
    
    if (event.currentTarget.checkValidity() === true) {
        updateCategoryApiRequest(id, name, description, image)
        .then(data => {
            if (data.success === "category updated") {
                navigate("/admin/categories");
            }
          })
        .catch(er => {
            setUpdateCategoryResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
        })
    }

    setValidated(true);
  };

    useEffect(() => {
        fetchCategory(id)
        .then(data => {
            setCategory(data);
        })
        .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
    }, [id])

  return (
    <Container>

      <Row className="justify-content-md-center mt-5">
        <Col md={1}>
          <Link to="/admin/categories" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        <Col md={6}>
          <h1>Modifica categoria</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formBasicFirstName">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                name="name"
                required
                type="text"
                defaultValue={category.name}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Descrizione</Form.Label>
              <Form.Control
                name="description"
                required
                type="text"
                defaultValue={category.description} 
              />
            </Form.Group>


            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Immagine</Form.Label>
              <Form.Control
                name="image"
                required
                type="text"
                defaultValue={category.image} 
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              MODIFICA
            </Button>
            {updateCategoryResponseState.error}

          </Form>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center">
        <Col md={6}>
        </Col>
      </Row>

    </Container>
  );
};

export default EditCategoryPageComponent;
